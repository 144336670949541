import colors from 'vuetify/lib/util/colors'

let themeConfig = {
    dark: true,
    colors: {
        background: '#05050c',
        surface: '#10101c',
        lightSurface: '#1a1a28',
        primary: colors.orange.base,
        secondary: colors.grey.base,
        accent: colors.cyan.base,
        error: colors.red.accent3,
        info: colors.lime.accent3,
        success: colors.green.accent3,
        warning: colors.deepOrange.lighten1,
    },
}

export default themeConfig;