import environnements from '@/config/firebase/firebaseConfig'
import { initializeApp } from 'firebase/app'

let config = environnements.dev
// if (!window.location.host.includes('localhost')) {
//   config = environnements.prod
// }

const app = initializeApp(config)

export default app
