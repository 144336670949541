<template>
  <v-sheet class="mb-3" elevation="6">
      <v-navigation-drawer expand-on-hover rail @update:rail="updateRail" rail-width="60" width="300" permanent location="right" style="border: none;" elevation="5">
          <v-list class="d-flex flex-column justify-space-between" style="height: 100%;">
            <div style="height: 100%; overflow-y: auto; overflow-x: hidden;">
              <span v-for="category in navContent">
                <span v-for="item in category">
                  <router-link :to="item.path" style="text-decoration:none">
                    <v-list-item link :color="item.dev ? 'error' : item.superAdminOnly ? 'info' : 'success'" :active="$route.path == item.path" density="compact">
                      <template v-slot:prepend>
                        <v-badge :color="!isDeploy && notifs[item.name] > 0 ? 'accent' : 'transparent'" dot>
                          <v-icon v-if="item.dev" color="error">{{ item.icon }}</v-icon>
                          <v-icon v-else-if="item.superAdminOnly" color="info">{{ item.icon }}</v-icon>
                          <v-icon v-else color="success">{{ item.icon }}</v-icon>
                        </v-badge>
                      </template>
                      <v-list-item-title>
                        <h3 v-if="item.dev" :class="$route.path == item.path ? 'text-error': 'text-secondary'" v-text="item.title"></h3>
                        <h3 v-else-if="item.superAdminOnly" :class="$route.path == item.path ? 'text-info': 'text-secondary'" v-text="item.title"></h3>
                        <h3 v-else :class="$route.path == item.path ? 'text-success': 'text-secondary'" v-text="item.title"></h3>
                      </v-list-item-title>
                      <template v-slot:append v-if="isDeploy && notifs[item.name] > 0">
                        <v-badge inline color="accent" text-color="background" :content="notifs[item.name]"></v-badge>
                      </template>
                    </v-list-item>
                  </router-link>
                </span>
                <div class="my-2">
                  <v-divider></v-divider>
                </div>
              </span>
            </div>
          </v-list>
      </v-navigation-drawer>
  </v-sheet>
</template>

<script>
import navItems from "@/data/navItems.js";
import { getAuth,  } from '@firebase/auth'
import { useProfileStore } from '@/stores/profile'

export default {
  setup() {
      return {
          navItems,
          store: useProfileStore()
      }
  },
  data() {
    return {
      unsub: [],
      isOpen: true,
      isDeploy: false,
      notifs:{
        Parameters : 0,
        Teams : 0,
        Assets : 0,
        Period : 0,
        Event : 0,
        Results : 0,
        Templates : 0,
        Dev : 0,
      }
    }
  },
  computed: {
    navContent() {
      let nav = []
      
      if(!this.store.profile) {
        return nav
      }

      navItems.forEach(cat => {
        let items = []

        cat.forEach(item => {
          let itemRoute = this.$router.resolve({ path: item.path })
          
          let visible = true
          if(!this.store.profile || this.store.profile.role == 'User') {
            visible = false
          }
          if(itemRoute.meta.accessRules.devNeeded && !this.store.profile.dev) {
            visible = false
          }
          if(itemRoute.meta.accessRules.accountNeeded && itemRoute.meta.accessRules.roleNeeded.includes('User')){
            visible = false
          }

          if(visible) {
            item.dev = itemRoute.meta.accessRules.devNeeded

            if(itemRoute.meta.accessRules.roleNeeded.includes('Admin')){
              item.superAdminOnly = false
            }else if(itemRoute.meta.accessRules.roleNeeded.includes('SuperAdmin')){
              item.superAdminOnly = true
            }

            item.name = itemRoute.name
            item.title = itemRoute.meta.title
            
            items.push(item)
          }
        })

        if (items.length > 0) {
          nav.push(items)
        }
      })

      return nav
    }
  },
  methods: {
      updateRail(e) {
        this.isDeploy = !e;
      },
      logout(){
        getAuth().signOut()
        this.$router.push('/login')
      },
  },
}

</script>