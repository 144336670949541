import { getFirestore, doc, query, collection, where, getDocs, deleteDoc } from 'firebase/firestore'
import { useAppStore } from '@/stores/appdata'

const db = getFirestore()


let getCollectionPath = async (collectionName) => {
  const appStore = useAppStore()

  let collectionRef = collection(db, "/appdata")
  let documentsQuery = query(collectionRef, where("application", "==", appStore.app.id))
  let response = await getDocs(documentsQuery)

  let appdata = null
  let first = true
  
  response.forEach(document => {
    if(first){
      appdata = document.data()
      appdata.id = document.id
    }else{
      let documentRef = doc(db, "/appdata", document.id)
      deleteDoc(documentRef)
    }

  })
  return `appdata/${appdata.id}/${collectionName}`
}

export default getCollectionPath