<template>
    <v-footer app class="d-flex justify-space-around align-center white">
        <i class="text-caption text-center text-secondary" v-text="'Copyright ©'+new Date().getFullYear()+' Loesus Pedagogia'"></i>
    </v-footer>
</template>

<script>

export default {
  data: () => ({
    
  }),
}

</script>