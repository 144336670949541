import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc, documentId } from "firebase/firestore"

const db = getFirestore()
const collectionName = 'applications/'

function docToInstance(document) {
  const data = document.data()
  return !data || data.type !== 'app_myFirstBusiness' ? null : new Application(document.id, data.organisation, data.name, data.type, data.archived, data.users, data.autoArchivingDate)
}
export default class Application {
  constructor(id, organisation, name, type, archived, users, autoArchivingDate) {
    this.id = id
    this.organisation = organisation
    this.name = name
    this.type = type
    this.archived = archived
    this.users = users
    this.autoArchivingDate = autoArchivingDate
  }

  static listenById(orgaId, callback) {
    return onSnapshot(doc(db, collectionName, orgaId), snapshot => {
      callback(docToInstance(snapshot))
    })
  }
}
