import { defineStore } from 'pinia'

export const useCollectionStore = defineStore('collections', {
  state: () => ({
    teams: [],
    periods: [],
    allPeriods: [],
    loaded: false
  })
})
