<template>
  <div class="px-3 d-flex flex-row align-center justify-space-between" v-if="profileStore && profileStore.profile" style="width: 100%;">
    <div class="d-flex flex-row align-center justify-space-between">
      <v-img height="50" width="50" :src="require('@/assets/images/Logo.svg')"></v-img>
      <span class="mx-2" v-if="$vuetify.display.smAndUp">My first Business<span v-if="$vuetify.display.mdAndUp"> - V {{ version }}</span></span>
      <v-chip size="small" :color="realeaseData.color" class="mx-2" v-if="$vuetify.display.smAndUp && realeaseData.display">{{ realeaseData.text }}</v-chip>
    </div>
    <v-spacer></v-spacer>
    <div class="d-flex flex-row align-center justify-space-between" v-if="profileStore.team">
      <div class="d-flex flex-column align-center justify-center">
        <h4 class="text-accent">{{appStore.app.name}}</h4>
        <h4 class="text-info">{{profileStore.team.name + " - " + profileStore.team.publicName}}</h4>
      </div>
    </div>
    <div v-else>
      <h4 class="text-accent d-inline">{{appStore.app.name}}</h4>
      <span v-if="profileStore.profile.dev" class="ml-3">({{ appStore.app.id }})</span>
    </div>
    <v-spacer></v-spacer>
    <div class="d-flex flex-row align-center justify-space-between">
      <Avatar :profile="profileStore.profile" size="40" badge></Avatar>
      <div class="mx-2 d-flex flex-column" v-if="$vuetify.display.smAndUp">
        <span class="text-accent text-caption" v-text="profileStore.profile.lastname"></span>
        <span class="text-accent text-caption" v-text="profileStore.profile.firstname"></span>
      </div>
    </div>
  </div>
</template>

<script>

import { useProfileStore } from '@/stores/profile'
import { useAppStore } from '@/stores/appdata.js'
import packageJSON from '@/../package.json'

import Avatar from '@/components/users/Avatar.vue'
import TeamLogo from '@/components/TeamLogo.vue'

export default {
  components: {
    Avatar,
    TeamLogo
  },
  data: () => ({
    profileStore: useProfileStore(),
    appStore: useAppStore(),
    version: packageJSON.version,
    realeaseData: packageJSON.realeaseData,
  }),
}

</script>