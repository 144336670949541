<template>
  <v-navigation-drawer v-model="menuIsOpen" location="left" temporary style="width: 300px; transition: 500ms;" :style="menuIsOpen ? 'max-width: 100%' : 'max-width: 0'">
    <v-list class="d-flex flex-column justify-space-between" style="height: 100%;">
      <div style="height: 100%; overflow-y: auto; overflow-x: hidden;">
        <span v-for="category in navContent">
          <span v-for="item in category">
            <router-link :to="item.path" style="text-decoration:none">
              <v-list-item link color="primary" :active="$route.path == item.path" density="compact" class="py-5">
                <template v-slot:prepend>
                  <v-badge class="mx-2" color="transparent" dot>
                    <v-icon color="error" v-if="item.dev">{{ item.icon }}</v-icon>
                    <v-icon color="info" v-else-if="item.superAdminOnly">{{ item.icon }}</v-icon>
                    <v-icon color="success" v-else-if="item.adminOnly">{{ item.icon }}</v-icon>
                    <v-icon color="primary" v-else>{{ item.icon }}</v-icon>
                  </v-badge>
                </template>
                <v-list-item-title>
                  <h3 :class="$route.path == item.path ? 'text-error': 'text-secondary'" v-text="item.title"  v-if="item.dev"></h3>
                  <h3 :class="$route.path == item.path ? 'text-info': 'text-secondary'" v-text="item.title" v-else-if="item.superAdminOnly"></h3>
                  <h3 :class="$route.path == item.path ? 'text-success': 'text-secondary'" v-text="item.title"  v-else-if="item.adminOnly"></h3>
                  <h3 :class="$route.path == item.path ? 'text-primary': 'text-secondary'" v-text="item.title"  v-else></h3>
                </v-list-item-title>
                <template v-slot:append v-if="notifs[item.name] > 0">
                  <v-badge inline color="accent" text-color="background" :content="notifs[item.name]"></v-badge>
                </template>
              </v-list-item>
            </router-link>
          </span>
          <div class="my-2">
            <v-divider></v-divider>
          </div>
        </span>
      </div>
      <div>
        <v-list-item disabled class="py-5"></v-list-item>
        <!-- <span v-if="store.profile.role == 'SuperAdmin'">
          <a style="text-decoration:none">
            <v-list-item link color="accent" density="compact">
              <template v-slot:prepend>
                <v-icon color="accent">mdi-earth-arrow-right</v-icon>
              </template>
              <v-list-item-title class="text-accent">Changer de monde</v-list-item-title>
            </v-list-item>
          </a>
        </span> -->
        <span>
          <a @click.prevent.stop="logout" style="text-decoration:none">
            <v-list-item link color="primary" density="compact">
              <template v-slot:prepend>
                <v-icon color="error">mdi-logout</v-icon>
              </template>
              <v-list-item-title class="text-error">Déconnexion</v-list-item-title>
            </v-list-item>
          </a>
        </span>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import navItems from "@/data/navItems.js"
import { getAuth,  } from '@firebase/auth'
import { useProfileStore } from '@/stores/profile'

export default {
    setup() {
      return {
        navItems,
        store: useProfileStore(),
        notifs:{
          Home : 0,
          Company : 0,
          MessageCenter : 0,
          Depot: 0,
          Buildings : 0,
          Politics : 0,
          Staff : 0,
          Trainings : 0,
          Offers : 0,
          Contracts : 0,
          Communication : 0,
          Bank : 0,
          Sales : 0,
          Finance : 0,
          Data : 0,
          Parameters : 0,
          Teams : 0,
          Assets : 0,
          Period : 0,
          Event : 0,
          Results : 0,
          Templates : 0,
          Dev : 0,
        }
      }
    },
    data() {
        return {
            unsub: [],
            menuIsOpen : false,
        }
    },
    computed: {
      navContent() {
        let nav = []
        
        if(!this.store.profile) {
          return nav
        }

        navItems.forEach(cat => {
          let userItems = []
          let adminItems = []

          cat.forEach(item => {
            let itemRoute = this.$router.resolve({ path: item.path })
            
            let visible = true
            if(itemRoute.meta.accessRules.accountNeeded && !this.store.profile) {
              visible = false
            }
            if(itemRoute.meta.accessRules.devNeeded) {
              visible = false
            }
            if(itemRoute.meta.accessRules.accountNeeded && !itemRoute.meta.accessRules.roleNeeded.includes('User')){
              visible = false
            }

            if(visible) {
              item.title = itemRoute.meta.title
              item.name = itemRoute.name
              userItems.push(item)
            }
          })

          cat.forEach(item => {
            let itemRoute = this.$router.resolve({ path: item.path })
            
            let visible = true
            if(!this.store.profile || this.store.profile.role == 'User') {
              visible = false
            }
            if(itemRoute.meta.accessRules.devNeeded && !this.store.profile.dev) {
              visible = false
            }
            if(itemRoute.meta.accessRules.accountNeeded && itemRoute.meta.accessRules.roleNeeded.includes('User')){
              visible = false
            }

            if(visible) {
              item.dev = itemRoute.meta.accessRules.devNeeded
              item.adminOnly = true

              if(itemRoute.meta.accessRules.roleNeeded.includes('Admin')){
                item.superAdminOnly = false
              }else if(itemRoute.meta.accessRules.roleNeeded.includes('SuperAdmin')){
                item.superAdminOnly = true
              }

              item.name = itemRoute.name
              item.title = itemRoute.meta.title
              
              adminItems.push(item)
            }
          })
          if (userItems.length > 0) {
            nav.push(userItems)
          }
          if (adminItems.length > 0) {
            nav.push(adminItems)
          }
        })
        return nav
      }
    },
    methods: {
      toggleMenu(){
        this.menuIsOpen = !this.menuIsOpen
      },
      logout(){
        getAuth().signOut()
        this.$router.push('/login')
      },
    },
}

</script>