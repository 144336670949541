let navItems = [
  [
    {
      path: '/',
      icon: 'mdi-monitor-dashboard',
    },
    {
      path: '/Company',
      icon: 'mdi-home-city-outline',
    }
  ],
  [
    {
      path: '/MessageCenter',
      icon: 'mdi-email-open-outline',
    },
    {
      path: '/Depot',
      icon: 'mdi-file-outline',
    }
  ],
  [
    {
      path: '/Offers',
      icon: 'mdi-tag-outline',
    },
    {
      path: '/Contracts',
      icon: 'mdi-script-text-outline',
    },
    {
      path: '/Staff',
      icon: 'mdi-badge-account-outline',
    },
    {
      path: '/Buildings',
      icon: 'mdi-office-building',
    },
    {
      path: '/Tools',
      icon: 'mdi-car-side',
    },
  ],
  [
    {
      path: '/Trainings',
      icon: 'mdi-school-outline',
    },
    {
      path: '/Communication',
      icon: 'mdi-bullhorn-outline',
    },
    {
      path: '/Bank',
      icon: 'mdi-bank-outline',
    },
  ],
  [
    // {
    //   path: '/Politics',
    //   icon: 'mdi-gavel',
    // },
  ],
  [
    {
      path: '/Invoices',
      icon: 'mdi-cash',
    },
    {
      path: '/Finance',
      icon: 'mdi-chart-bar-stacked',
    },
    {
      path: '/Data',
      icon: 'mdi-magnify',
    },
  ],
  [
    {
      path: '/Parameters',
      icon: 'mdi-tools',
    },
    {
      path: '/GameData',
      icon: 'mdi-file-cog-outline',
    },
    {
      path: '/Assets',
      icon: 'mdi-package-variant-closed',
    },
    {
      path: '/MarketConfig',
      icon: 'mdi-chart-bell-curve',
    },
    {
      path: '/Teams',
      icon: 'mdi-account-group-outline',
    },
    // {
    //   path: '/Period',
    //   icon: 'mdi-timer-sand',
    // },
    // {
    //   path: '/Event',
    //   icon: 'mdi-calendar-clock',
    // },
    // {
    //   path: '/CashBonus',
    //   icon: 'mdi-cash-multiple',
    // },
    {
      path: '/Results',
      icon: 'mdi-finance',
    },
  ],
  [
    {
      path: '/Templates',
      icon: 'mdi-content-save-outline',
    },
  ],
  [
    {
      path: '/Dev',
      icon: 'mdi-xml',
    },
  ],
]

export default navItems